import * as React from 'react';

import s from './BigBlurb.module.scss';
import { Image as ImageType } from '../../types';
import { LinkButton } from '../Button/Button';

import Image from "../Image";

export interface BigBlurbProps {
    title: string;
    text: string;
    image: ImageType;
    logo: ImageType;
    url: string;
    buttonText: string;
}

const BigBlurb: React.FC<BigBlurbProps> = ({
    title,
    image,
    logo,
    text,
    url,
    buttonText,
}) => {

    let mediaQueries = [
        {
            minWidth: 1660,
            src: image?.renditions.large.src,
        },
        {
            minWidth: 1280,
            src: image?.renditions.medium.src,
        },
        {
            minWidth: 1000,
            src: image?.renditions.xl.src,
        },
        {
            minWidth: 768,
            src: image?.renditions.large.src,
        },
        {
            minWidth: 480,
            src: image?.renditions.medium.src,
        },
    ];

    mediaQueries = mediaQueries.filter(x => x.src);
    
    return (
        <div className={s.Root}>
            <div className={s.Wrap}>
                {image && (
                    <Image
                        image={image.renditions.small}
                        containerClass={s.ImageContainer}
                        className={s.Image}
                        mediaQueries={mediaQueries}
                    />
                )}
                <div className={s.Content}>
                    {logo?.src && (
                        <img alt="" className={s.Logo} src={logo.src} />
                    )}
                    <h2
                        className={s.Title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <div
                        className={s.Text}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    {url && (
                        <div className={s.DesktopButton}>
                            <LinkButton href={url}>{buttonText}</LinkButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BigBlurb;
