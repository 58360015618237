import * as React from 'react';
import s from './Hero.module.scss';

export interface HeroProps {
    heroText?: string
}

const Hero: React.FunctionComponent<HeroProps> = ({heroText}) => {
    return (
        <div className={s.Hero}>
            <div
                className={s.Container}
                dangerouslySetInnerHTML={{ __html: heroText }}
            />
        </div>
    );
};

export default Hero;
