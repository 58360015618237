import * as React from 'react';
import { Card } from '../../types';

import { useScrolledIntoView } from '../../hooks';

import s from './ResultBalls.module.scss';

export interface ResultBallsProps {
    items: Card[];
    heading: string;
}

const isServer = () => typeof window === 'undefined';

const ResultBalls: React.FC<ResultBallsProps> = ({ items = [], heading }) => {
    const [ViewSensor, isInView] = useScrolledIntoView({partialVisibility: true});
    const colors = ['Pink', 'Green', 'Ice', 'Lavender'];

    const randomItems = () => {
        items.sort(function(a, b) {
            return 0.5 - Math.random();
        });

        return items.slice(0, 9);
    };

    // Skip server side render due to difference in backend/frontend random order
    if (isServer()) {
        return null;
    }

    return (
        <ViewSensor>
            <div className={s.Root}>
                <h2 className={s.Heading}>{heading}</h2>
                <ul className={s.Balls}>
                    {randomItems().map((item, index) => {
                        const color = colors[
                            Math.floor(
                                Math.random() * Math.floor(colors.length)
                            )
                        ];

                        const colorClass = s[`Ball${color}`];

                        const animateCl = Math.random() > 0.5
                            ? s['Ball--AnimateTop']
                            : s['Ball--AnimateBottom'];

                        return (
                            <li
                                key={index}
                                className={`${s.Ball} Ball${index + 1} ${colorClass} ${isInView ? animateCl : ''}`}
                            >
                                <div className={s.Content}>
                                    <h3 className={s.Title}>{item.title}</h3>
                                    <p className={s.Text}>{item.text}</p>
                                    {item?.image?.src && (
                                        <img
                                            className={s.Logo}
                                            loading="lazy"
                                            src={item.image.src}
                                            alt={item.image.alt}
                                        />
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </ViewSensor>

    );
};

export default ResultBalls;
