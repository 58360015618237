import * as React from 'react';
import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useSpring, animated } from 'react-spring'

import s from './CardIconText.module.scss';
import { Image, Link as LinkProps } from "../../types";
import Link from "../Link";

export interface CardIconTextProps {
    image: Image,
    title: string,
    text: string,
    link: LinkProps,
    cardIndex: number
}

const CardIconText: React.FC<CardIconTextProps> = ({ title, text, image, link, cardIndex }) => {
    const [visible, setVisible] = useState(false);

    const fadeInProps = useSpring({
        config: { mass: 1, tension: 280, friction: 50 },
        opacity: visible ? 1 : 0,
        yOffset: visible ? 0 : 100,
    });

    const onScrolledIntoView = (isVisible:boolean) => {
        if(!isVisible) {
            return;
        }

        setTimeout(() => setVisible(true), cardIndex * 200);
    }

    const imageStyle = {
        backgroundImage: `url(${image ? image.src : ''})`,
        opacity: fadeInProps.opacity,
        transform: fadeInProps.yOffset.interpolate((value) =>
            `translate3d(0, -${value}px, 0)`
        )
    }

    return (
        <VisibilitySensor onChange={onScrolledIntoView}>
            <animated.article className={s.Root} style={imageStyle}>
                {link && link.href ? (
                    <Link className={s.Link} {...link}>
                        <h3 className={s.Title}>{title}</h3>
                    </Link>
                ) : (
                    <h3 className={s.Title}>{title}</h3>
                )}
                <p className={s.Text}>{text}</p>
            </animated.article>
        </VisibilitySensor>
    )
};

export default CardIconText
