import React, { PureComponent } from 'react';

import { basePageWrap } from '../BasePage';
import s from './StartPage.module.scss';

import Hero from '../../components/Hero';
import { HeroProps } from '../../components/Hero/Hero';
import Billboard, {
    BillboardProps,
} from '../../components/Billboard/Billboard';
import BigBlurb, {
    BigBlurbProps,
} from '../../components/BigBlurb/BigBlurb';
import CardIconText, {
    CardIconTextProps,
} from '../../components/CardIconText/CardIconText';
import CardImageBranding, {
    CardImageBrandingProps,
} from '../../components/CardImageBranding/CardImageBranding';
import CardTextCta, { CardTextCtaProps } from '../../components/CardTextCta/CardTextCta';
import CardArticleList, {
    CardArticleListProps,
} from '../../components/CardArticleList/CardArticleList';
import { LinkButton } from '../../components/Button/Button';
import { Link } from '../../types';
import ResultBalls, {
    ResultBallsProps,
} from '../../components/ResultBalls/ResultBalls';

import PromoVideos, { PromoVideosProps } from '../../components/PromoVideos/PromoVideos';

interface StartPageProps {
    hero: HeroProps;
    billboard: BillboardProps;
    offers: CardIconTextProps[];
    projects: CardImageBrandingProps[];
    projectsLink: Link;
    videos: PromoVideosProps;
    articles: CardArticleListProps;
    resultBalls: ResultBallsProps;
    bigBlurb: BigBlurbProps;
    siteSetting: any;
}

class StartPage extends PureComponent<StartPageProps> {
    state = {};

    render() {
        const {
            hero,
            billboard,
            offers,
            projects,
            projectsLink,
            articles,
            videos,
            resultBalls,
            bigBlurb,
            siteSetting
        } = this.props;

        const contact: CardTextCtaProps = siteSetting.contactModal;

        const getProjectImageMediaQueries = ({
            renditions: renditions,
            narrow = false,
        }) => {
            let mediaQueries = [];

            if (narrow) {
                mediaQueries = [
                    {
                        minWidth: 960,
                        src: renditions.small.src,
                    },
                ];
            }

            mediaQueries = [
                ...mediaQueries,
                {
                    minWidth: 480,
                    src: renditions.large.src,
                },
            ];
            return mediaQueries.filter((x) => x.src);
        };


        return (
            <div className={s.Root}>
                <Hero {...hero} />
                <div className={s.Billboard}>
                    <Billboard lazy={false} {...billboard} />
                </div>

                {offers && (
                    <div className={s.Wrap}>
                        <h2 className="sr-only">Vårt erbjudande</h2>
                        <div className={s.OfferWrapper}>
                            {offers.map((offer, index) => (
                                <div key={index} className={s.OfferItem}>
                                    <CardIconText
                                        {...offer}
                                        cardIndex={index}
                                        image={offer?.image?.renditions?.thumb}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {projects.length > 0 && (
                    <div className={s.Wrap}>
                        <h2 className="sr-only">Våra projekt</h2>
                        <div className={s.ProjectWrapper}>
                            <div className={s.ProjectRow1}>
                                <div className={s.ProjectRowFiller}></div>
                                <div className={s.ProjectItem1}>
                                    <CardImageBranding
                                        {...projects[0]}
                                        image={projects[0]?.image}
                                        mediaQueries={getProjectImageMediaQueries(
                                            {
                                                renditions: projects[0].image.renditions,
                                                narrow: false,
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={s.ProjectRow2}>
                                <div className={s.ProjectItem2}>
                                    <CardImageBranding
                                        {...projects[1]}
                                        image={projects[1]?.image}
                                        mediaQueries={getProjectImageMediaQueries(
                                            {
                                                renditions: projects[1].image.renditions,
                                                narrow: false,
                                            }
                                        )}
                                    />
                                </div>
                                <div className={s.ProjectRowFiller}></div>
                            </div>
                            <div className={s.ProjectRow3}>
                                <div className={s.ProjectItem3}>
                                    <CardImageBranding
                                        {...projects[2]}
                                        image={projects[2]?.image}
                                        mediaQueries={getProjectImageMediaQueries(
                                            {
                                                renditions: projects[2].image.renditions,
                                                narrow: true,
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={s.ProjectsAll}>
                                <LinkButton {...projectsLink}>
                                    Visa alla case
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                )}

                {contact && (
                    <div className={s.Wrap}>
                        <div className={s.ContactWrapper}>
                            <CardTextCta {...contact} pageType="StartPage" />
                        </div>
                    </div>
                )}

                {videos && (
                    <div className={s.Wrap}>
                        <div className={s.Videos}>
                            <PromoVideos {...videos} pageType="StartPage" />
                        </div>
                    </div>
                )}

                {bigBlurb && bigBlurb.title && bigBlurb.text &&
                    <BigBlurb {...bigBlurb} />
                }

                {resultBalls.items.length > 0 && (
                    <div className={s.Wrap}>
                        <ResultBalls {...resultBalls} />
                    </div>
                )}

                {articles && (
                    <div className={s.Wrap}>
                        <h2 className="sr-only">Aktuellt</h2>
                        <div className={s.ArticlesWrapper}>
                            <CardArticleList {...articles} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default basePageWrap(StartPage);
